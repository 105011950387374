// This component is used to show a test image of iOS keyboard, which can be helpful for testing
// What the keyboard will appear as on web pages

import React, { useEffect, useState } from "react";
import Image from "next/image";

const MockIOSKeyboard = () => {
  const [showMockKeyboard, setShowMockKeyboard] = useState(false);
  const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
  let currentPosition = 0;
  const handleKeyDown = (event) => {
    if (event.keyCode === konamiCode[currentPosition]) {
      currentPosition++;
      if (currentPosition === konamiCode.length) {
        setShowMockKeyboard(true);
        // Add your custom functionality or Easter egg here
        currentPosition = 0;
      }
    } else {
      currentPosition = 0;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {showMockKeyboard && (
        <div className="fixed inset-x-0 bottom-0 bg-blue-400">
          <div className="absolute z-50 flex items-center justify-center pt-4 w-full">
            <button
              className="bg-blue-600 text-white text-xl px-4 py-3 rounded-md"
              onClick={(e) => {
                setShowMockKeyboard(!showMockKeyboard);
              }}
            >
              Close mock keyboard
            </button>
          </div>
          <Image
            src={`${process.env.BASE_PATH}/iphone-mockup-keyboard.png`}
            width={390}
            height={440}
            objectFit="cover"
          />
        </div>
      )}
    </>
  );
};

export default MockIOSKeyboard;
