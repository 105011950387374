import {
  useState
} from "react";
import {
  setInstructionsModalDismissed
} from "./local-storage";
import {
  trackClick,
  trackSettingsChange
} from "./tracking";

export function usePageState() {
  const [instructionsModalOpen, setInstructionsOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [timerEnabled, setTimerEnabled] = useState(undefined);
  const [disableCustomKeyboard, setDisableCustomKeyboard] = useState(true);
  const [darkModeForced, setDarkModeForced] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const setInstructionsOpenHandler = (val) => {
    setInstructionsOpen(val);
    if (val === false) {
      setInstructionsModalDismissed();
    }
  };

  const setTimerEnabledWithTracking = async (val) => {
    setTimerEnabled(val);
    await trackSettingsChange("timer_enabled", val);
  };

  const setDisableCustomKeyboardWithTracking = async (val) => {
    setDisableCustomKeyboard(val);
    await trackSettingsChange("custom_keyboard_disabled", val);
  };

  const setInstructionsOpenWithTracking = async (val) => {
    setInstructionsOpen(val);
    if (val) {
      await trackClick("instructions_modal");
    }
  };

  const setSettingsModalOpenWithTracking = async (val) => {
    setSettingsModalOpen(val);
    if (val) {
      await trackClick("settings_modal");
    }
  };

  return {
    instructionsModalOpen,
    setInstructionsOpen,
    setInstructionsOpenWithTracking,
    settingsModalOpen,
    setSettingsModalOpenWithTracking,
    timerEnabled,
    setTimerEnabledWithTracking,
    setTimerEnabled,
    disableCustomKeyboard,
    setDisableCustomKeyboard,
    setDisableCustomKeyboardWithTracking,
    darkModeForced,
    setDarkModeForced,
    menuOpen,
    setMenuOpen,
    setInstructionsOpenHandler,
  };
}