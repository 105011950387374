import * as React from "react";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { trackClueUsed } from "../util/tracking";
import { PUZZLE_STATES } from "./puzzle";
import { isPuzzleComplete } from "../util/puzzle-util";
import GTMUtils from "../util/GTM.utils";

const CLUES = {
  "number-syllables": {
    id: "number-syllables",
    showPhrase: "Next clue: syllable count",
    emoji: "💬",
    styles: [
      "font-medium",
      "border-pink-300 dark:border-pink-900",
      "text-hot-pink",
      "bg-pink-200/50 dark:bg-pink-900/20",
      "hover:bg-pink-300/50 dark:hover:bg-pink-900/40",
    ],
  },
  "blurred-depiction": {
    id: "blurred-depiction",
    showPhrase: "Next clue: blurry image",
    emoji: "🎨",
    styles: [
      "font-medium",
      "border-purple-300 dark:border-purple-900",
      "text-purple-700 dark:text-purple-500",
      "bg-purple-200/50 dark:bg-purple-900/20",
      "hover:bg-purple-300/50 dark:hover:bg-purple-900/40",
    ],
  },
  "first-initials": {
    id: "first-initials",
    showPhrase: "Next clue: first letters",
    emoji: "🔠",
    styles: [
      "font-medium",
      "border-blue-300 dark:border-blue-900",
      "text-blue-500",
      "bg-blue-100/50 dark:bg-blue-900/20",
      "hover:bg-blue-300/50 dark:hover:bg-blue-900/40",
    ],
  },
  "more-direct-clue": {
    id: "more-direct-clue",
    showPhrase: "Next clue: easier prompt",
    emoji: "✏️",
    styles: [
      "font-medium",
      "border-orange-300 dark:border-orange-900",
      "text-red-orange",
      "bg-orange-100/50 dark:bg-orange-900/20",
      "hover:bg-orange-300/50 dark:hover:bg-orange-900/40",
    ],
  },
};

const SHOWN_STYLES = [
  "grayscale",
  "font-medium",
  "text-gray-400 dark:text-gray-500",
  "bg-gray-100/50 dark:bg-gray-900/20",
  "dark:border-gray-800",
];

const Hints = (props) => {
  const isPuzzleDone = isPuzzleComplete(props.puzzleState);
  const clueToDiv = (clue) => {
    const hintState = props.hintState[clue.id];
    const clueUsed = hintState.used;
    const handleClick = async (evt) => {
      evt.preventDefault();
      GTMUtils.pushEventToGtm('hint_button', { phrase: clue.showPhrase });
      if (clueUsed || isPuzzleDone) {
        return;
      }
      hintState.setter(true);
      await trackClueUsed(clue.id, props.puzzleId);
    };

    return (
      <div
        key={clue.id}
        onClick={handleClick}
        className={`gtm-hint-button relative sm:rounded-lg rounded-md border ${clue.styles.join(
          " "
        )} px- sm:px-5 py-0.5 sm:py-3 shadow-sm flex flex-col gap-0.5 sm:gap-1 ${isPuzzleDone ? "" : "cursor-pointer"
          }`}
      >
        <div className="font-semibold text-lg sm:text-xl flex items-center justify-center">
          <div className="text-2xl mr-2 inline-block">{clue.emoji}</div>
          <span>{clue.showPhrase}</span>
          <ArrowSmallRightIcon className="h-6 w-6 ml-1 inline-block"></ArrowSmallRightIcon>
        </div>
      </div>
    );
  };
  let clue;
  if (!props.hintState["number-syllables"].used) {
    clue = clueToDiv(CLUES["number-syllables"]);
  } else if (!props.hintState["blurred-depiction"].used) {
    clue = clueToDiv(CLUES["blurred-depiction"]);
  } else if (!props.hintState["first-initials"].used) {
    clue = clueToDiv(CLUES["first-initials"]);
  } else if (!props.hintState["more-direct-clue"].used) {
    clue = clueToDiv(CLUES["more-direct-clue"]);
  } else {
    clue = (
      <></>
    );
  }

  return (
    <div className={`justify-center my-3 sm:my-5`}>
      <div className="text-center sm:max-w-sm mx-6 sm:m-auto">{clue}</div>
    </div>
  );
};

export default Hints;
