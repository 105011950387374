import {
  getExperimentValue,
  getTwoferGooferState,
  setExperimentValue,
} from "./local-storage";
import {
  isPuzzleComplete,
  shuffle
} from "./puzzle-util";
import {
  trackExperimentTreatment
} from "./tracking";

const EXPERIMENTS = {
  EASY_TWOFERS: "EASY_TWOFERS",
  FEWER_ADS: "FEWER_ADS",
  FEWER_ADS_V2: "FEWER_ADS_V2",
  SMART_ERRORS: "SMART_ERRORS"
};

const EASY_TWOFER_IDS = [193, 202, 207];

export function getCompletedPuzzleIds() {
  const fullState = getTwoferGooferState();
  const completedPuzzleIds = [];
  for (const puzzleId of Object.keys(fullState)) {
    const puzzleState = fullState[puzzleId].puzzleState;
    const puzzleComplete = isPuzzleComplete(puzzleState);
    if (puzzleComplete) {
      completedPuzzleIds.push(Number(puzzleId));
    }
  }
  return completedPuzzleIds;
}

export function smartErrorsEnabled(experiments) {
  if (!experiments.includes(EXPERIMENTS.SMART_ERRORS)) {
    return false;
  }

  let experimentBucket = getExperimentValue(EXPERIMENTS.SMART_ERRORS);
  if (!experimentBucket) {
    experimentBucket = Math.random() < 0.5 ? "CONTROL" : "TREATMENT";
    setExperimentValue(EXPERIMENTS.SMART_ERRORS, experimentBucket);
    trackExperimentTreatment(EXPERIMENTS.SMART_ERRORS, experimentBucket);
  }
  return experimentBucket == "TREATMENT";
}

export function forceEasyTwofers(experiments) {
  if (!experiments.includes(EXPERIMENTS.EASY_TWOFERS)) {
    return false;
  }

  const completedPuzzleIds = getCompletedPuzzleIds();
  const numCompletedPuzzles = completedPuzzleIds.length;
  if (numCompletedPuzzles >= 3) {
    return false;
  }

  let experimentBucket = getExperimentValue(EXPERIMENTS.EASY_TWOFERS);
  if (!experimentBucket && numCompletedPuzzles > 0) {
    return false;
  }

  if (!experimentBucket) {
    experimentBucket = Math.random() < 0.5 ? "CONTROL" : "TREATMENT";
    setExperimentValue(EXPERIMENTS.EASY_TWOFERS, experimentBucket);
    trackExperimentTreatment(EXPERIMENTS.EASY_TWOFERS, experimentBucket);
  }
  if (experimentBucket == "CONTROL") {
    return false;
  } else if (experimentBucket == "TREATMENT") {
    return true && Boolean(getNextEasyTwoferId());
  }
}

export function getNextEasyTwoferId(currentId) {
  const completedPuzzleIds = getCompletedPuzzleIds();
  const numCompletedPuzzles = completedPuzzleIds.length;
  if (numCompletedPuzzles >= 3) {
    return null;
  }
  if (currentId) {
    completedPuzzleIds.push(currentId);
  }
  const candidateIds = EASY_TWOFER_IDS.filter(
    (id) => !completedPuzzleIds.includes(id)
  );
  const shuffledCandidates = shuffle(candidateIds);
  return shuffledCandidates[0];
}

export function getNumTwofersBetweenAdsThreshold(experiments) {
  if (!experiments.includes(EXPERIMENTS.FEWER_ADS_V2)) {
    return 0;
  }

  let experimentBucket = getExperimentValue(EXPERIMENTS.FEWER_ADS_V2);
  if (!experimentBucket) {
    experimentBucket = Math.random() < 0.5 ? "CONTROL" : "TREATMENT";
    setExperimentValue(EXPERIMENTS.FEWER_ADS_V2, experimentBucket);
    trackExperimentTreatment(EXPERIMENTS.FEWER_ADS_V2, experimentBucket);
  }

  return experimentBucket == "CONTROL" ? 0 : 1;
}