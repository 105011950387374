
const pushEventToGtm = (eventName, data = null) => {
    window.dataLayer = window.dataLayer || { };
    window.dataLayer.push({
      'event': eventName,
      ...data
    });
}

export default {
    pushEventToGtm,
}