import supabaseProd from "./supabase-prod";

const supabaseDev = {
  from: (tableName) => {
    const prodTable = supabaseProd.from(tableName);
    const devTable = new SupabaseTable(prodTable);
    return devTable;
  },

  auth: supabaseProd.auth,
};

class SupabaseTable {
  constructor(prodTable) {
    this.prodTable = prodTable;
  }

  insert(el) {
    console.log("Stubbed insert:", el);
    return;
  }

  select() {
    return this.prodTable.select.apply(this.prodTable, arguments);
  }
}

export default supabaseDev;
