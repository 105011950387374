export function getNumberHintsUsed(puzzleState) {
  if (!puzzleState) {
    return 0;
  }
  return [
    puzzleState.showBlurredDepiction,
    puzzleState.showFirstInitials,
    puzzleState.showMoreDirectClue,
    puzzleState.showSyllables,
  ].filter(Boolean).length;
}

export const DEFAULT_PUZZLE = {
  id: null,
  prompt: "A flying mammal adorned with a fedora",
  answer: "Bat hat",
  alternate_answer: "Cat hat",
  alternate_prompt: "A vampire's best friend in a stylish top",
  first_word_num_syllables: 1,
  second_word_num_syllables: 1,
  puzzle_number: 10,
  image:
    "https://rfwouezcbvpmywbsuans.supabase.co/storage/v1/object/public/twofer-goofer-images/gf.png",
  blurred_image:
    "https://rfwouezcbvpmywbsuans.supabase.co/storage/v1/object/public/twofer-goofer-images/gf-blurred.png?t=2022-08-07T21%3A22%3A53.653Z",
};
