import { Fragment, useState, useEffect, createRef, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import Stats from "./stats";
import { PUZZLE_STATES } from "./puzzle";
import { getNumberHintsUsed } from "../util/puzzle";
import { isPuzzleComplete } from "../util/puzzle-util";

function buildPastPuzzles(
  answer,
  imageUrl,
  prompt,
  puzzleNumber,
  puzzleId,
  currentPuzzleDone,
  currentPuzzleSolved,
  historicalState,
  numHintsUsedToday,
  mostRecentPuzzleId
) {
  const puzzles = [];
  for (let i = mostRecentPuzzleId; i > 0; i--) {
    const puzzleState = historicalState && historicalState[i];
    const isTodaysCompletedPuzzle = i == puzzleId && currentPuzzleDone;
    const isOldCompletedPuzzle =
      i != puzzleId && puzzleState && isPuzzleComplete(puzzleState.puzzleState);
    if (isTodaysCompletedPuzzle) {
      puzzles.push({
        answer: answer,
        imageUrl: imageUrl,
        puzzleNumber: puzzleNumber,
        solved: currentPuzzleSolved,
        prompt: prompt,
        numHintsUsed: numHintsUsedToday,
      });
    } else if (isOldCompletedPuzzle) {
      puzzles.push({
        answer: puzzleState.answer,
        imageUrl: puzzleState.image,
        puzzleNumber: puzzleState.puzzle_number,
        prompt: puzzleState.prompt,
        numHintsUsed: getNumberHintsUsed(puzzleState),
        solved: puzzleState.puzzleState === PUZZLE_STATES.SOLVED,
      });
    }
  }
  return puzzles;
}

export default function HistoryModal({
  open,
  setOpen,
  answer,
  imageUrl,
  prompt,
  puzzleNumber,
  puzzleId,
  currentPuzzleDone,
  currentPuzzleSolved,
  historicalState,
  numHintsUsedToday,
  mostRecentPuzzleId,
  numSecondsToday,
  viewportHeight,
  scaleFactor,
}) {
  let puzzles = buildPastPuzzles(
    answer,
    imageUrl,
    prompt,
    puzzleNumber,
    puzzleId,
    currentPuzzleDone,
    currentPuzzleSolved,
    historicalState,
    numHintsUsedToday,
    mostRecentPuzzleId
  );
  const puzzlesOverflow = puzzles.length >= 5;
  let firstPuzzleIdx;
  if (!puzzlesOverflow) {
    // This means we won't be scrolling and will be showing the images in order.
    // We want the oldest image to be first, and to select the last image.
    puzzles = puzzles.reverse();
    firstPuzzleIdx = puzzles.length - 1;
  } else {
    // Here we're using flex-row-reverse, so the newest image will be first and shown on the right.
    firstPuzzleIdx = 0;
  }
  const [selectedPuzzleIdx, setSelectedPuzzleIdx] = useState(firstPuzzleIdx);
  const puzzleContainerRef = createRef();
  const currentPuzzle = puzzles[selectedPuzzleIdx];

  useEffect(() => {
    setSelectedPuzzleIdx(firstPuzzleIdx);
  }, [firstPuzzleIdx]);

  const puzzleToIcon = (puzzle, idx) => {
    const onClick = (evt) => {
      evt.preventDefault();
      setSelectedPuzzleIdx(idx);
    };
    let borderClasses = "";
    if (idx === selectedPuzzleIdx) {
      borderClasses = "border-4 rounded-xl border-red-400";
    }
    return (
      <div
        className={`flex-shrink-0 relative w-14 h-14 cursor-pointer rounded-md history-thumbnail ${borderClasses}`}
        onClick={onClick}
        key={idx}
      >
        <Image
          className={`rounded-lg w-full h-auto`}
          src={puzzle.imageUrl}
          alt={puzzle.answer}
          width="0"
          height="0"
          sizes="100vw"          
          objectFit="contain"
          loading="eager"
        />
      </div>
    );
  };

  let content;
  if (currentPuzzle) {
    content = (
      <div>
        <div>
          <Dialog.Title
            as="h3"
            className="text-center text-xl font-semibold w-full z-20 mt-4 dark:text-white"
          >
            History
          </Dialog.Title>
          <div className="px-3 sm:px-6 text-center">
            <Stats
              historicalState={historicalState}
              mostRecentPuzzleId={mostRecentPuzzleId}
            ></Stats>
            <div className="mt-3 sm:mt-4"></div>
            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg drop-shadow">
              <div className="relative w-full h-full">
                <div className="image-gradient w-full h-32 z-10 absolute rounded-t-lg"></div>
                <div className="text-white text-xl absolute z-30 top-3 text-center font-semibold w-full uppercase">
                  {currentPuzzle.answer}
                </div>
                <div className="rounded-full h-6 w-6 bg-white dark:bg-gray-900 dark:text-white text-center absolute z-50 top-3.5 left-3.5 text-sm font-semibold text-gray-900 flex items-center justify-center">
                  {currentPuzzle.puzzleNumber}
                </div>
                <Image
                  className="rounded-t-lg w-full h-auto"
                  src={currentPuzzle.imageUrl}
                  alt={currentPuzzle.answer}
                  width="0"
                  height="0"
                  sizes="100vw"                  
                  objectFit="contain"
                  loading="eager"
                />
              </div>
              <div className="mt-2 pb-2 text-center flex flex-col gap-1">
                <div className="font-semibold text-lg px-2 leading-tight dark:text-white">
                  {currentPuzzle.prompt}
                </div>
                <div className="flex gap-2 items-center justify-center text-base font-semibold text-gray-500 dark:text-gray-400">
                  <div>{currentPuzzle.numHintsUsed}/4 hints used</div>
                  <div>•</div>
                  <div>
                    {currentPuzzle.solved ? (
                      <div className="flex items-center gap-1">
                        <span className="text-lg mt-0.5">✅</span> Solved
                      </div>
                    ) : (
                      <div className="flex items-center gap-1">
                        <span className="text-lg mt-0.5">❌</span> Not solved
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex overflow-x-auto space-x-1 w-full mt-2 no-scrollbar ${puzzlesOverflow ? "flex-row-reverse" : ""
                }`}
              ref={puzzleContainerRef}
            >
              {puzzles.map(puzzleToIcon)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <div>
          <Dialog.Title
            as="h3"
            className="text-center text-xl font-semibold w-full z-20 mt-4 dark:text-white"
          >
            History
          </Dialog.Title>
          <div className="text-center px-4 mt-4 font-semibold gap-1 items-center">
            <div className="flex justify-center pb-4">
              <div className="text-6xl my-4 p-8 bg-yellow-50 dark:bg-yellow-900/20 rounded-full">
                🤔
              </div>
            </div>
            <div className="text-2xl font-medium text-gray-500 dark:text-gray-400 pb-8">
              {"Check back here after solving today's puzzle!"}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [modalScaleFactor, setModalScaleFactor] = useState(1);

  // Change the useEffect hook to the following
  const [isResizing, setIsResizing] = useState(true);

  const afterEnterResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setModalScaleFactor(viewportHeight * 0.95 / containerRef.current.offsetHeight);
      setIsResizing(false);
    }
  };

  const shouldScale = viewportHeight < 500 && containerHeight > viewportHeight;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 t-0" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 top-0">
          <div className={`flex items-center sm:items-start sm:pt-6 justify-center min-h-full p-2 text-center sm:p-0bg-black ${isResizing ? 'opacity-0' : 'opacity-100'}`}
            ref={containerRef}
            style={{
              transform: shouldScale ? `scale(${modalScaleFactor})` : ``,
              transformOrigin: shouldScale ? `top` : ``,
              top: shouldScale ? `20px` : ``,
            }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterEnter={afterEnterResize}
            >
              <Dialog.Panel className="relative bg-white dark:bg-gray-900 rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-80 sm:max-w-md">
                <div
                  className="absolute top-4 right-4 cursor-pointer z-30"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-5 w-5 text-gray-500"></XMarkIcon>
                </div>
                {content}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
