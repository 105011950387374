import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import packageInfo from '../package.json';

export default function InstructionsModal({ open, setOpen, isDarkMode, viewportHeight, scaleFactor }) {

  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [modalScaleFactor, setModalScaleFactor] = useState(1);

  // Change the useEffect hook to the following
  const [isResizing, setIsResizing] = useState(true);

  const afterEnterResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setModalScaleFactor(viewportHeight * 0.95 / containerRef.current.offsetHeight);
      setIsResizing(false);
    }
  };

  const shouldScale = viewportHeight < 500 && containerHeight > viewportHeight;
  const commitRef = process.env.NEXT_PUBLIC_COMMIT_REF?.slice(0, 7);
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 bg-gray-500 dark:bg-black dark:bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0">
          <div className={`flex items-center max-w-sm sm:items-start sm:pt-6 justify-center min-h-full p-4 text-center mx-auto sm:p-0 ${isResizing ? 'opacity-0' : 'opacity-100'}`}
            ref={containerRef}
            style={{
              transform: shouldScale ? `scale(${modalScaleFactor})` : ``,
              transformOrigin: shouldScale ? `top` : ``,
              top: shouldScale ? `20px` : ``,
            }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterEnter={afterEnterResize}
            >
              <Dialog.Panel className="relative bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-auto  max-h-[calc(100dvh-20px)] shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-5 w-5 text-gray-400"></XMarkIcon>
                </div>
                <div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-center text-xl leading-6 font-medium text-gray-800 dark:text-gray-100 pb-2"
                    >
                      How to play
                    </Dialog.Title>
                    <div className="mt-1 sm:mt-3 text-md sm:text-lg  text-gray-700 dark:text-gray-400 flex flex-col gap-y-2">
                      <p>
                        <span className="font-semibold">Twofer Goofers</span>{" "}
                        are pairs of rhyming words that form a nonsensical
                        phrase.
                      </p>
                      <p>
                        Use the clue to find the rhyme and solve the puzzle.
                      </p>
                      <p>Stumped? Use up to 4 hints.</p>
                      <div className="sm:pb-2" />
                      <div className="mb-[10px]">
                        {isDarkMode ? (
                          <Image
                            className="flex justify-center aspect-auto rounded-lg"
                            src={`${process.env.BASE_PATH}/tutorial-dark.gif`}
                            width="320"
                            height="250"
                            alt="Twofer Goofer tutorial"
                            layout="intrinsic"
                            objectFit="contain"
                            loading="eager"
                          />
                        ) : (
                          <Image
                            className="flex justify-center aspect-auto rounded-lg "
                            src={`${process.env.BASE_PATH}/tutorial.gif`}
                            width="320"
                            height="250"
                            alt="Twofer Goofer tutorial"
                            layout="intrinsic"
                            objectFit="contain"
                            loading="eager"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-real-blue dark:bg-blue-600 text-xl font-semibold text-white hover:bg-blue-700"
                    onClick={() => setOpen(false)}
                  >
                    Start
                  </button>
                </div>
                <p className="text-xs text-gray-500 text-center mt-3">
                  v{packageInfo.version} - {commitRef}
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
