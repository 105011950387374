import { useEffect, useState } from "react";
import { trackAnswerFeedback, trackFetchError } from "../util/tracking";
import { checkAnswer } from "../util/puzzle-util";
import { smartErrorsEnabled } from "../util/experiment";

function checkWordWithTimeout(word) {
  const wordPromise = fetch(`https://api.datamuse.com/words?ml=${word}`);
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ timeout: true });
    }, 3000);
  });
  return Promise.race([wordPromise, timeoutPromise])
    .then((resp) => resp.json())
    .catch((err) => {
      const timeout = Boolean(err && err.timeout);
      const message = err && err.message;
      trackFetchError({
        type: "word_validity_check",
        word: word,
        timeout: timeout,
        message: message,
      });
      return null;
    });
}

function getRhymingWords(word) {
  const rhymeResponsePromise = fetch(
    `https://api.datamuse.com/words?rel_rhy=${word}`
  );
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ timeout: true });
    }, 3000);
  });
  return Promise.race([rhymeResponsePromise, timeoutPromise])
    .then((resp) => resp.json())
    .then((rhymes) => rhymes.map((rhyme) => rhyme.word))
    .catch((err) => {
      const timeout = Boolean(err && err.timeout);
      const message = err && err.message;
      trackFetchError({
        type: "word_rhyme_check",
        word: word,
        timeout: timeout,
        message: message,
      });
      return null;
    });
}

const SmartErrors = ({
  submittedAnswer,
  answer,
  alternateAnswer,
  experiments,
}) => {
  const [errorExplanation, setErrorExplanation] = useState(null);
  useEffect(() => {
    if (
      !submittedAnswer ||
      checkAnswer(answer, submittedAnswer) ||
      checkAnswer(alternateAnswer, submittedAnswer)
    ) {
      // answer is not present or correct, no need to check for errors
      return setErrorExplanation(null);
    }

    if (!smartErrorsEnabled(experiments)) {
      return setErrorExplanation(null);
    }

    async function checkErrors() {
      // verify if each word is a valid word
      const [firstWord, secondWord] = submittedAnswer.trim().split(/\s+/);

      const [firstWordResponse, secondWordResponse, rhymingWords] =
        await Promise.all([
          checkWordWithTimeout(firstWord),
          checkWordWithTimeout(secondWord),
          getRhymingWords(firstWord),
        ]);
      if (!firstWordResponse || !secondWordResponse) {
        // We resolve to null if there was an error, so return.
        return setErrorExplanation(null);
      }

      // if firstWordJson has an empty array, or if secondWordJson has an empty array, then the word is not valid
      const firstWordWrong = firstWordResponse.length == 0;
      const secondWordWrong = secondWordResponse.length == 0;

      if (firstWordWrong || secondWordWrong) {
        const wordsWrong = [
          firstWordWrong && firstWord,
          secondWordWrong && secondWord,
        ]
          .filter(Boolean)
          .join(" and ");
        trackAnswerFeedback({
          type: "mispelling",
          wordsWrong: wordsWrong,
        });
        return setErrorExplanation(`Check your spelling on ${wordsWrong}!`);
      }

      // if both words are valid, see if they rhyme
      if (!rhymingWords) {
        return setErrorExplanation(null);
      }

      // for each word, see if it rhymes with the second word
      const wordsRhyme = rhymingWords.some(
        (word) => word.toLowerCase() == secondWord.toLowerCase()
      );
      if (wordsRhyme) {
        return setErrorExplanation(null);
      }
      trackAnswerFeedback({
        type: "no_rhyme",
        answer: submittedAnswer,
      });
      setErrorExplanation(`The two words need to rhyme!`);
    }

    checkErrors();
  }, [submittedAnswer, answer]);

  if (!errorExplanation) {
    return null;
  }

  return <div className="flex flex-col text-red-500">{errorExplanation}</div>;
};

export default SmartErrors;
