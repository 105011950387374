import { BackspaceIcon } from "@heroicons/react/24/outline";
import { PUZZLE_STATES } from "./puzzle";

const TOP_ROW_LETTERS = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
const MIDDLE_ROW_LETTERS = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
const BOTTOM_ROW_LETTERS = ["Z", "X", "C", "V", "B", "N", "M"];

export const SPECIAL_KEYS = {
  BACKSPACE: "BACKSPACE",
  ENTER: "ENTER",
};

export default function Keyboard({
  puzzleState,
  handleKeyboardEvent,
  disableCustomKeyboard,
}) {
  const handleTouchEnd = (evt) => {
    removeTapClass(evt);
    handleKeyboardEvent(evt.currentTarget.dataset.key);
  };
  const addTapClass = (evt) => {
    const el = evt.currentTarget;
    el.classList.remove("bg-gray-200", "dark:bg-gray-800");
    el.classList.add("bg-gray-400", "dark:bg-gray-700");
  };
  const removeTapClass = (evt) => {
    const el = evt.currentTarget;
    el.classList.add("bg-gray-200", "dark:bg-gray-800");
    el.classList.remove("bg-gray-400", "dark:bg-gray-700");
  };
  const toLetterEl = (chr) => {
    const isLetter = chr.length === 1;
    return (
      <div
        className={`text-gray-700 bg-gray-200 dark:text-white dark:bg-gray-800 py-2.5 px-2.5 text-lg font-semibold flex justify-center rounded-sm select-none ${isLetter ? "w-7-5" : ""
          }`}
        onTouchStart={addTapClass}
        onTouchEnd={handleTouchEnd}
        data-key={chr}
        key={chr}
      >
        {chr}
      </div>
    );
  };
  const toEnterButton = (chr) => {
    return (
      <div
        className={`text-gray-700 bg-gray-200 dark:text-white dark:bg-gray-800 py-2.5 px-1 text-sm font-medium grow-0 flex items-center rounded-sm justify-center w-11 select-none`}
        onTouchStart={addTapClass}
        onTouchEnd={handleTouchEnd}
        data-key={chr}
        key={chr}
      >
        {chr}
      </div>
    );
  };
  const backspace = (
    <div
      className="bg-gray-200 text-gray-700 dark:text-white dark:bg-gray-800 py-2.5 px-2 text-lg font-semibold flex items-center justify-center w-11 select-none"
      onTouchStart={addTapClass}
      onTouchEnd={handleTouchEnd}
      data-key={SPECIAL_KEYS.BACKSPACE}
    >
      <BackspaceIcon className="h-5 w-5"></BackspaceIcon>
    </div>
  );

  const shouldHide =
    (puzzleState != PUZZLE_STATES.WAITING_FOR_INPUT &&
      puzzleState != PUZZLE_STATES.READY_TO_SUBMIT &&
      puzzleState != PUZZLE_STATES.WRONG_ANSWER) ||
    disableCustomKeyboard;
  return (
    <div
      className={`fixed bottom-0 left-0 w-full flex flex-col bg-white dark:bg-gray-900 pt-4 border-gray-100 dark:border-gray-800 border-t-2 gap-1.5 mb-2 sm:hidden ${shouldHide ? "hidden" : ""
        }`}
    >
      <div className="flex gap-1.5 justify-center">
        {TOP_ROW_LETTERS.map(toLetterEl)}
      </div>
      <div className="flex gap-1.5 justify-center">
        {MIDDLE_ROW_LETTERS.map(toLetterEl)}
      </div>
      <div className="flex gap-1.5 justify-center">
        {toEnterButton(SPECIAL_KEYS.ENTER)}
        {BOTTOM_ROW_LETTERS.map(toLetterEl)}
        {backspace}
      </div>
    </div>
  );
}
