import { Fragment } from "react";
import Router from "next/router";
import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import { trackClick } from "../util/tracking";
export default function Menu({
  open,
  setOpen,
  setSettingsModalOpen,
  setInstructionsOpen,
  setCalendarModalOpenWithTracking,
  playRandomTwofer,
  enableSettingsModal,
  viewportHeight,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black/70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                  <div
                    className={`flex h-full flex-col overflow-y-hidden bg-white dark:bg-gray-900 shadow-xl justify-between px-4 sm:px-6 ${viewportHeight < 500 ? "py-2" : "py-6"
                      }`}
                  >
                    <div>
                      <div>
                        <div className="flex items-start justify-between">
                          <Dialog.Title
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white mt-1 cursor-pointer hover:text-gray-400 dark:hover:text-gray-400"
                            onClick={() => {
                              setOpen(false);
                              Router.push("/?cid=1");
                            }}
                          >
                            Twofer Goofer
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-4 flex-1 text-gray-700 dark:text-gray-100 space-y-4">
                        <div
                          className="w-full h-24 flex rounded bg-yellow-50 dark:bg-yellow-900/20 border dark:border-yellow-900 cursor-pointer hover:text-gray-400 dark:hover:text-gray-400"
                          onClick={() => {
                            setOpen(false);
                            setCalendarModalOpenWithTracking(true);
                          }}
                        >
                          <div className="w-1/2 p-3">
                            <div className="text-lg font-semibold pl-1">
                              Solve past Twofers →
                            </div>
                          </div>
                          <div className="w-1/2 relative">
                            <Image
                              className="flex justify-center aspect-auto rounded"
                              src={`${process.env.BASE_PATH}/menu-splash-art.png`}
                              alt="Twofer Goofer Archive"
                              layout="fill"
                            />
                          </div>
                        </div>
                        <div
                          className={`flex items-center cursor-pointer hover:text-gray-400 dark:hover:text-gray-400
                            ${viewportHeight < 500 ? "space-x-2" : "space-x-4"}`}
                          onClick={async () => {
                            await trackClick("play_random");
                            setOpen(false);
                            playRandomTwofer();
                          }}
                        >
                          <div className="w-7 h-7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 256 256"
                              className="w-full h-full"
                            >
                              <rect
                                width="28"
                                className="hover:fill-gray-400 dark:hover:text-gray-400"
                                height="28"
                                fill="none"
                              />
                              <path
                                d="M237.66,178.34a8,8,0,0,1,0,11.32l-24,24A8,8,0,0,1,200,208V192a72.15,72.15,0,0,1-57.65-30.14l-41.72-58.4A56.1,56.1,0,0,0,55.06,80H32a8,8,0,0,1,0-16H55.06a72.12,72.12,0,0,1,58.59,30.15l41.72,58.4A56.08,56.08,0,0,0,200,176V160a8,8,0,0,1,13.66-5.66ZM143,107a8,8,0,0,0,11.16-1.86l1.2-1.67A56.08,56.08,0,0,1,200,80V96a8,8,0,0,0,13.66,5.66l24-24a8,8,0,0,0,0-11.32l-24-24A8,8,0,0,0,200,48V64a72.15,72.15,0,0,0-57.65,30.14l-1.2,1.67A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.16,1.86l-1.2,1.67A56.1,56.1,0,0,1,55.06,176H32a8,8,0,0,0,0,16H55.06a72.12,72.12,0,0,0,58.59-30.15l1.2-1.67A8,8,0,0,0,113,149Z"
                                className="fill-current hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-400"
                              />
                            </svg>
                          </div>

                          <span
                            className={`font-semibold ${viewportHeight < 500 ? "text-lg" : "text-xl"
                              }`}
                          >
                            Random puzzle
                          </span>
                        </div>
                        {enableSettingsModal && (
                          <div
                            className={`flex items-center cursor-pointer hover:text-gray-400 dark:hover:text-gray-400
                            ${viewportHeight < 500 ? "space-x-2" : "space-x-4"
                              }`}
                            onClick={() => {
                              setOpen(false);
                              setSettingsModalOpen(true);
                            }}
                          >
                            <Cog8ToothIcon className="h-7 w-7" />
                            <span
                              className={`font-semibold ${viewportHeight < 500 ? "text-lg" : "text-xl"
                                }`}
                            >
                              Settings
                            </span>
                          </div>
                        )}
                        <div
                          className={`flex items-center cursor-pointer hover:text-gray-400 dark:hover:text-gray-400
                            ${viewportHeight < 500 ? "space-x-2" : "space-x-4"
                            }`}
                          onClick={() => {
                            setOpen(false);
                            setInstructionsOpen(true);
                          }}
                        >
                          <QuestionMarkCircleIcon className="h-7 w-7"></QuestionMarkCircleIcon>
                          <span
                            className={`font-semibold ${viewportHeight < 500 ? "text-lg" : "text-xl"
                              }`}
                          >
                            How to Play
                          </span>
                        </div>                                            
                      </div>
                    </div>
                    <div className="text-left flex-shrink-0 text-base leading-6 text-gray-900 dark:text-white mt-1 external-link">
                      <span className="text-md font-semibold">
                        Contact:{" "}
                        <a
                          href="mailto:goofer@twofergoofer.com"
                          className="underline text-blue-600 hover:text-blue-700"
                        >
                          goofer@twofergoofer.com
                        </a>
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
