import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Stats from "./stats";
import { PUZZLE_STATES } from "./puzzle";
import { getNumberHintsUsed } from "../util/puzzle";
import { formatSec } from "../util/puzzle-util";
import {
  addDays,
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  getDaysInMonth,
  format,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Link from "next/link";

export default function CalendarModal({
  open,
  puzzles,
  setOpen,
  historicalState,
  mostRecentPuzzleId,
  viewportHeight,
  experiments,
  setId,
  calendarScrollPosition,
  setCalendarScrollPosition,
}) {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [modalScaleFactor, setModalScaleFactor] = useState(1);
  const [selectedHistoryPuzzleId, setSelectedHistoryPuzzleId] = useState(null);

  // Change the useEffect hook to the following
  const [isResizing, setIsResizing] = useState(true);

  const afterEnterResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setModalScaleFactor(
        (viewportHeight * 0.95) / containerRef.current.offsetHeight
      );
      setIsResizing(false);
    }
  };

  const shouldScale = viewportHeight < 601 && containerHeight > viewportHeight;

  const MONTH_NAMES = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "S"];

  function convertPuzzleNumberToDate(selectedPuzzleNumber) {
    // look up puzzleId from puzzles
    const selectedPuzzle = puzzles.find(
      (puzzle) => puzzle.id === selectedPuzzleNumber
    );
    const selectedPuzzleDate = selectedPuzzle?.day ?? null;
    return selectedPuzzleDate;
  }

  const getCalendarArray = (date) => {
    const start = startOfMonth(date);

    const todayInEasternTime = zonedTimeToUtc(new Date(), "America/New_York");

    todayInEasternTime.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds

    const puzzleStart = new Date(2022, 6, 22); // set the puzzle start date

    const days = Array.from({ length: getDaysInMonth(date) }, (_, i) => {
      const day = new Date(date.getFullYear(), date.getMonth(), i + 1);
      // if the day is in the future or before the puzzle start date, return null
      if (day > todayInEasternTime || day < puzzleStart) return null;
      return day;
    });

    const firstDay = start.getDay();
    const total = firstDay + getDaysInMonth(date);
    const rows = Math.ceil(total / 7);

    const calendar = [];
    for (let i = 0; i < rows * 7; i++) {
      if (i >= firstDay && i < total && days[i - firstDay] !== null) {
        calendar.push({
          date: days[i - firstDay],
          isEmpty: false,
        });
      } else {
        calendar.push({
          date: null,
          isEmpty: true,
        });
      }
    }
    return calendar;
  };

  const getPuzzlesSolvedCountForMonth = (calendar, historicalState) => {
    let solvedCount = 0;
    let actualDays = 0;

    for (const day of calendar) {
      if (!day.isEmpty) {
        actualDays++;
        const formattedDate = day?.date?.toISOString().split("T")[0] ?? null;
        // iterate through puzzles.day and find the one that matches the date
        const matchingPuzzle = puzzles.find(
          (puzzle) => puzzle.day === formattedDate
        );
        const matchingPuzzleId = matchingPuzzle?.id ?? null;
        const matchingHistoricalStatePuzzle =
          historicalState[matchingPuzzleId] ?? null;

        if (
          matchingHistoricalStatePuzzle &&
          (matchingHistoricalStatePuzzle.puzzleState === PUZZLE_STATES.SOLVED ||
            matchingHistoricalStatePuzzle.puzzleState === PUZZLE_STATES.GAVE_UP)
        ) {
          solvedCount++;
        }

        // actualDays++;
        // const puzzleId = Math.ceil((day.date - new Date(2022, 6, 22)) / (1000 * 60 * 60 * 24)) + 1;
        // const key = Object.keys(historicalState).find(key => historicalState[key].id === puzzleId);
        // if (key && (historicalState[key].puzzleState === PUZZLE_STATES.SOLVED || historicalState[key].puzzleState === PUZZLE_STATES.GAVE_UP)) {
        //   solvedCount++;
        // }
      }
    }

    return [solvedCount, actualDays];
  };

  const Day = ({ day, historicalData }) => {
    const isFuture =
      day.date > new Date(zonedTimeToUtc(new Date(), "America/New_York"));
    const isEmpty = day.isEmpty || day.date === null;

    // Default styling for day
    let dayStyles = {
      backgroundColor: "bg-blue-500",
      borderStyles: "",
    };

    // Check for specific historical data and adjust styling accordingly
    if (historicalData && !historicalData.isEmpty && !isEmpty) {
      switch (historicalData.puzzleState) {
        case PUZZLE_STATES.GAVE_UP:
          dayStyles.backgroundColor = "bg-red-500 top-[-1px] left-[-1px]";
          dayStyles.borderStyles = "border border-red-500 box-border ";
          break;
        case PUZZLE_STATES.SOLVED:
          dayStyles.backgroundColor = "bg-green-500 top-[-1px] left-[-1px]";
          dayStyles.borderStyles = "border border-green-500 box-border";
          break;
        default:
          break;
      }
    }

    if (isFuture || isEmpty) {
      return <div className="relative" />;
    }

    const formattedDate = day?.date?.toISOString().split("T")[0] ?? null;
    // iterate through puzzles.day and find the one that matches the date
    const matchingPuzzle = puzzles.find(
      (puzzle) => puzzle.day === formattedDate
    );
    const matchingPuzzleNumber = matchingPuzzle?.puzzle_number ?? null;
    const linkhref = matchingPuzzleNumber ? '/daily/' + matchingPuzzleNumber : '/';

    return (
      <div
        className={`relative w-[34px] h-[34px] ${dayStyles.borderStyles} rounded-sm overflow-hidden cursor-pointer`}
      >
        <div
          className={`w-[14px] h-[14px] overflow-hidden absolute z-20 text-[11px] ${dayStyles.backgroundColor} text-white`}
        >
          <div className="flex items-center justify-center h-full w-full">
            {format(day.date, "d")}
          </div>
        </div>
        <div
          className={`flex w-full h-full items-center justify-center ${
            isEmpty ? "" : "bg-blue-200 dark:bg-blue-900"
          }`}
        >
          {historicalData &&
          !historicalData.isEmpty &&
          (historicalData.puzzleState === PUZZLE_STATES.GAVE_UP ||
            historicalData.puzzleState === PUZZLE_STATES.SOLVED) ? (
            <div
              className=""
              onClick={async () => {
                const formattedDate =
                  day?.date?.toISOString().split("T")[0] ?? null;
                // iterate through puzzles.day and find the one that matches the date
                const matchingPuzzle = puzzles.find(
                  (puzzle) => puzzle.day === formattedDate
                );
                const matchingPuzzleId = matchingPuzzle.id ?? null;
                setSelectedHistoryPuzzleId(matchingPuzzleId);
                setCalendarScrollPosition(scrollPositionRef.current);
              }}
            >
              <Image
                src={historicalData.image}
                alt={historicalData.prompt}
                height={40}
                width={40}
              />
            </div>
          ) : (
            !isEmpty && (
              <Link 
                href={linkhref} 
                passHref
                className="w-full h-full flex items-center justify-center text-[16px] mt-1 ml-1 dark:text-blue-300 text-blue-600 font-semibold"
                onClick={
                  // route to play an unsolved puzzle
                  async () => {
                    setCalendarScrollPosition(scrollPositionRef.current);                   
                    setOpen(false);
                  }
                }
              >                                             
                ?
              </Link>
            )
          )}
        </div>
      </div>
    );
  };

  const Month = ({ date, historicalState }) => {
    const calendar = getCalendarArray(date);

    const getHistoricalData = (date) => {
      // format data as yyyy-MM-dd
      // const formattedDate = format(date, 'yyyy-MM-dd');
      const formattedDate = date?.toISOString()?.split("T")[0] ?? null;

      // iterate through puzzles.day and find the one that matches the date
      const matchingPuzzle = puzzles.find(
        (puzzle) => puzzle.day === formattedDate
      );
      const matchingPuzzleId = matchingPuzzle ? matchingPuzzle?.id : null;

      if (date && matchingPuzzleId) {
        // Find the key in historicalState that has this puzzle number
        return {
          ...historicalState[matchingPuzzleId],
          date: date,
          isEmpty: false,
          notSolved: false,
          puzzleId: matchingPuzzleId,
        };
      }

      if (date == null) {
        // this is for date squares that don't actually exist in the calendar (i.e. gutter squares to complete the row)
        return { date: date, isEmpty: true };
      }
    };

    const [solvedCount, actualDays] = getPuzzlesSolvedCountForMonth(
      calendar,
      historicalState
    );

    return (
      <div className="mb-5">
        <div className="flex flex-row items-center justify-center mb-2">
          <h2 className="text-left font-medium text-md mr-auto">
            {MONTH_NAMES[date.getMonth()]} {date.getFullYear()}
          </h2>
          <span
            className={`text-sm font-medium
          ${solvedCount == actualDays ? "text-green-500" : "text-yellow-500"}
          `}
          >
            {solvedCount} / {actualDays}
          </span>
        </div>
        <div className="grid grid-cols-7 gap-1.5">
          {calendar.map((day, i) => (
            <Day
              key={i}
              day={day}
              historicalData={getHistoricalData(day.date)}
            />
          ))}
        </div>
      </div>
    );
  };
  const Calendar = ({ historicalState, scrollRef, scrollPositionRef }) => {
    const startDate = new Date(2022, 6); // June 2022
    const endDate = new Date(zonedTimeToUtc(new Date(), "America/New_York")); // Now
    const months = eachMonthOfInterval({ start: startDate, end: endDate });

    useEffect(() => {
      if (scrollRef.current !== null) {
        const handleScroll = () => {
          scrollPositionRef.current = scrollRef.current.scrollTop;
        };

        scrollRef.current.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts or before the next render
        return () => {
          if (scrollRef.current !== null) {
            scrollRef.current.removeEventListener("scroll", handleScroll);
          }
        };
      }
    }, [scrollPositionRef, scrollRef]);

    useEffect(() => {
      if (scrollRef.current !== null) {
          // initial state, so scroll to bottom of the div to show most recent puzzles
          setTimeout( () => {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }, 50);       
      }
    }, [scrollRef]);

    // Access the stored scroll position with scrollPositionRef.current

    return (
      <div
        className="overflow-y-auto overflow-x-hidden sm:max-h-[40vh] max-h-[50vh] hide-scrollbar"
        ref={scrollRef}
      >
        {months.map((month, i) => (
          <Month key={i} date={month} historicalState={historicalState} />
        ))}
      </div>
    );
  };

  const scrollRef = useRef();
  const scrollPositionRef = useRef(0); // To store the scroll position

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 t-0`}
        open={open} // Fixes bug on mobile dialog close https://github.com/tailwindlabs/headlessui/issues/1705#issuecomment-1201624374
        onClose={() => {
          setOpen(false);
          setCalendarScrollPosition(scrollPositionRef.current);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-0"
          leaveFrom="opacity-0"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 top-0">
          <div
            className={`flex items-center sm:items-start sm:pt-6 justify-center min-h-full p-2 text-center sm:p-0 ${
              isResizing ? "opacity-0" : "opacity-100"
            }`}
            ref={containerRef}
            style={{
              transform: shouldScale ? `scale(${modalScaleFactor})` : ``,
              transformOrigin: shouldScale ? `top` : ``,
              top: shouldScale ? `20px` : ``,
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="duration-0"
              leaveFrom="opacity-0 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterEnter={afterEnterResize}
            >
              <Dialog.Panel className="flex flex-col bg-white dark:bg-gray-900 rounded-lg text-left shadow-xl transform transition-all sm:my-8 w-80 sm:max-w-md">
                <Dialog.Title
                  as="h3"
                  className={`text-center text-xl font-semibold w-full z-20 dark:text-white sticky top-0 sm:pb-2 sm:pt-4 pt-3 pb-0 bg-white dark:bg-gray-900
                  ${selectedHistoryPuzzleId ? "" : "shadow-md"}
                  `}
                >
                  {selectedHistoryPuzzleId && (
                    <div
                      className="absolute top-4 left-4 cursor-pointer z-30"
                      onClick={() => {
                        setSelectedHistoryPuzzleId(null);
                      }}
                    >
                      <div className="flex flex-row">
                        <ChevronLeftIcon className="h-5 w-5 dark:text-gray-300 text-gray-500"></ChevronLeftIcon>
                      </div>
                    </div>
                  )}
                  {selectedHistoryPuzzleId == null && <p>Archive</p>}
                  {selectedHistoryPuzzleId && (
                    <p className="text-md">
                      Score for{" "}
                      {convertPuzzleNumberToDate(selectedHistoryPuzzleId)}
                    </p>
                  )}
                  <div
                    className="absolute top-4 right-4 cursor-pointer z-30"
                    onClick={() => {
                      setOpen(false);
                      setCalendarScrollPosition(scrollPositionRef.current);
                    }}
                  >
                    <XMarkIcon className="h-5 w-5 dark:text-gray-300 text-gray-500"></XMarkIcon>
                  </div>

                  {selectedHistoryPuzzleId == null && (
                    <div className="grid grid-cols-7 gap-1.5 px-5 mt-2 sm:mb-0 mb-2">
                      {DAYS_OF_WEEK.map((day_of_week, i) => (
                        <div
                          key={i}
                          className={`w-[34px] sm:h-[34px] h-[16px] sm:mt-0 text-sm sm:text-lg font-medium flex items-center justify-center text-gray-500 dark:text-gray-300`}
                        >
                          {day_of_week}
                        </div>
                      ))}
                    </div>
                  )}
                </Dialog.Title>
                {selectedHistoryPuzzleId == null && (
                  <>
                    <div className="dark:text-white">
                      <div>
                        <div className="px-5 sm:px-6 text-center mt-2">
                          <Calendar
                            historicalState={historicalState}
                            scrollRef={scrollRef}
                            scrollPositionRef={scrollPositionRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center p-5">
                      <p className="dark:text-white text-sm sm:text-md font-medium text-gray-40">
                        Lifetime stats
                      </p>
                      <Stats
                        historicalState={historicalState}
                        mostRecentPuzzleId={mostRecentPuzzleId}
                      ></Stats>
                    </div>
                  </>
                )}
                {selectedHistoryPuzzleId && (
                  <div>
                    <div className="px-3 sm:px-6 text-center">
                      <div className="mt-3 sm:mt-4"></div>
                      <div className="bg-gray-50 dark:bg-gray-800 rounded-lg drop-shadow">
                        <div className="relative w-full h-full">
                          <div className="image-gradient w-full h-32 z-10 absolute rounded-t-lg"></div>
                          <div className="text-white text-xl absolute z-30 top-3 text-center font-semibold w-full uppercase">
                            {historicalState[selectedHistoryPuzzleId]?.answer}
                          </div>
                          <div className="rounded-full h-6 w-6 bg-white dark:bg-gray-900 dark:text-white text-center absolute z-50 top-3.5 left-3.5 text-sm font-semibold text-gray-900 flex items-center justify-center">
                            {
                              historicalState[selectedHistoryPuzzleId]
                                ?.puzzle_number
                            }
                          </div>
                          <Image
                            className="rounded-t-lg w-full h-auto"
                            src={
                              historicalState[selectedHistoryPuzzleId]?.image
                            }
                            alt={
                              historicalState[selectedHistoryPuzzleId]?.answer
                            }
                            width="0"
                            height="0"
                            sizes="100vw"
                            objectFit="contain"
                            loading="eager"
                          />
                        </div>
                        <div className="mt-2 pb-2 text-center flex flex-col gap-1">
                          <div className="font-semibold text-lg px-2 leading-tight dark:text-white">
                            {historicalState[selectedHistoryPuzzleId]?.prompt}
                          </div>
                          <div className="flex gap-2 items-center justify-center text-base font-semibold text-gray-500 dark:text-gray-400">
                            <div>
                              {getNumberHintsUsed(
                                historicalState[selectedHistoryPuzzleId]
                              )}
                              /4 clues used
                            </div>
                            <div>•</div>
                            <div>
                              {historicalState[selectedHistoryPuzzleId]
                                ?.puzzleState == PUZZLE_STATES.SOLVED ? (
                                <div className="flex items-center gap-1">
                                  <span className="text-lg mt-0.5">✅</span>{" "}
                                  Solved
                                </div>
                              ) : (
                                <div className="flex items-center gap-1">
                                  <span className="text-lg mt-0.5">❌</span> Not solved
                                </div>
                              )}
                              {/* <p className="w-[100px]">{JSON.stringify g(historicalState[selectedHistoryPuzzleId])}</p> */}
                            </div>
                          </div>
                          <p className="dark:text-white">
                            {formatSec(
                              historicalState[selectedHistoryPuzzleId]
                                ?.puzzleTimeSec
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="my-3">
                        <button
                          onClick={() => setSelectedHistoryPuzzleId(null)}
                          className="text-blue-400 font-medium dark:bg-blue-900/50 bg-blue-200/50 w-full py-2 rounded-md"
                        >
                          <div className="flex flex-row items-center justify-center">
                            <p className="">← Back to calendar</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
