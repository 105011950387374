import { getPuzzleFromId } from "../util/puzzle-util";
import Puzzle from "./puzzle";
import{ useState } from "react";

export default function PuzzleWrapper({
  puzzles,
  initialId,
  dbg,
  isMobile,
  experiments,
  viewportHeight,
  scaleFactor,
  puzzleContainerRef,
  currentDate,
}) {
  const mostRecentPuzzleId = puzzles[puzzles.length - 1].id;
  const [id, setId] = useState(initialId);
  const puzzle = getPuzzleFromId(puzzles, id);
  const isTodayPuzzle = id == mostRecentPuzzleId;

  return (
    <Puzzle
      id={id}
      setId={setId}
      puzzle={puzzle}
      experiments={experiments}
      mostRecentPuzzleId={mostRecentPuzzleId}
      dbg={dbg}
      puzzles={puzzles}
      isMobile={isMobile}
      key={id}
      viewportHeight={viewportHeight}
      scaleFactor={scaleFactor}
      puzzleContainerRef={puzzleContainerRef}
      isTodayPuzzle={isTodayPuzzle}
      currentDate={currentDate}
    ></Puzzle>
  );
}
