import { getStreakData } from "../util/local-storage";
import { getNumberHintsUsed } from "../util/puzzle";
import { PUZZLE_STATES } from "./puzzle";

function buildStats(historicalState, mostRecentPuzzleId) {
  let todaysStreak = 0;
  let currentStreak = 0;
  let maxStreak = 0;
  let streakIncludesCurrentPuzzle = false;
  let totalNumHints = 0;
  let totalNumPuzzles = 0;
  let totalNumSolved = 0;
  let totalNumSeconds = 0;
  let numPuzzlesSolvedWithTime = 0;
  let streakData = getStreakData();
  
  for (let i = mostRecentPuzzleId; i > 0; i--) {
    const puzzleState = historicalState && historicalState[i];
    if (puzzleState) {
      totalNumPuzzles += 1;
    }
    if (!puzzleState || puzzleState.puzzleState != PUZZLE_STATES.SOLVED) {
      streakIncludesCurrentPuzzle = false;
      maxStreak = Math.max(maxStreak, currentStreak);
      currentStreak = 0;
      continue;
    }

    if (
      i == mostRecentPuzzleId &&
      puzzleState.puzzleState == PUZZLE_STATES.SOLVED
    ) {
      streakIncludesCurrentPuzzle = true;
    }

    currentStreak += 1;
    totalNumSolved += 1;
    if (puzzleState.puzzleTimeSec) {
      totalNumSeconds += puzzleState.puzzleTimeSec;
      numPuzzlesSolvedWithTime += 1;
    }
    if (streakIncludesCurrentPuzzle) {
      todaysStreak = currentStreak;
    }
    totalNumHints +=
      puzzleState.numHintsUsed || getNumberHintsUsed(puzzleState);
  }
  maxStreak = Math.max(maxStreak, currentStreak);

  let avgHints =
    totalNumSolved == 0
      ? 0
      : Math.round((totalNumHints / totalNumSolved) * 10) / 10;
  let solveRate = totalNumSolved / totalNumPuzzles;
  let avgSeconds;
  if (numPuzzlesSolvedWithTime > 0) {
    avgSeconds = Math.round(totalNumSeconds / numPuzzlesSolvedWithTime);
  }
  const solveRatePct = `${Math.round(solveRate * 100)}%`;

  return {
    totalNumSolved,
    avgHints,
    solveRatePct,
    // Use streak calculated at local storage level.
    todaysStreak: streakData.currentStreak,
    maxStreak: streakData.maxStreak,
    avgSeconds,
  };
}

export default function Stats({ historicalState, mostRecentPuzzleId }) {
  const stats = buildStats(historicalState, mostRecentPuzzleId);
  const statsWithText = [
    {
      value: stats.totalNumSolved,
      text: "solved puzzles",
    },
    {
      value: stats.avgHints,
      text: "avg. hints",
    },
  ];
  if (stats.avgSeconds) {
    statsWithText.push({
      value: stats.avgSeconds,
      text: "avg. seconds",
    });
  } else {
    statsWithText.push({
      value: stats.solveRatePct,
      text: "solve rate",
    });
  }

  statsWithText.push({
    value: stats.todaysStreak,
    text: "current streak",
  });
  statsWithText.push({
    value: stats.maxStreak,
    text: "max streak",
  });

  return (
    <div className="flex gap-2 mt-2 bg-gray-50 dark:bg-gray-800/40 p-2 rounded">
      {statsWithText.map(({ value, text }) => {
        return (
          <div key={text} className="w-full">
            <div className="text-xl font-semibold pb-1 dark:text-white">
              {value}
            </div>
            <div className="text-sm font-medium text-gray-400 dark:text-gray-500 leading-tight tracking-tighter">
              {text}
            </div>
          </div>
        );
      })}
    </div>
  );
}
