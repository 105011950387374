import React, { useEffect, useState, useRef } from "react";
import { formatSec } from "../util/puzzle-util";
import useInterval from "beautiful-react-hooks/useInterval";
import {
  getPersistedPuzzleState,
  getStoredPuzzleTimeSec,
  setPersistedPuzzleState,
} from "../util/local-storage";

export default function Timer({
  puzzleComplete,
  id,
  inputsRef,
}) {
  const [isTimerVisible, setIsTimerVisible] = useState(true);
  const [puzzleTimeSec, setPuzzleTimeSec] = useState(
    getStoredPuzzleTimeSec(id)
  );

  useEffect(() => {
    if (!inputsRef.current) {
      return;
    }
    const inputContainerRef = inputsRef.current.containerRef;
    if (!inputContainerRef.current) {
      return;
    };
    const observer = new IntersectionObserver(
      (entries) => {
        const newIsVisible = entries[0].isIntersecting === true;
        setIsTimerVisible(newIsVisible);
      },
      { threshold: [1] }
    );
    observer.observe(inputContainerRef.current);
  }, [inputsRef]);

  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["puzzleTimeSec"] = puzzleTimeSec;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, puzzleTimeSec]);

  useInterval(() => {
    if (puzzleComplete || document.hidden) {
      return;
    }
    const newPuzzleTimeSec = puzzleTimeSec + 1;
    setPuzzleTimeSec(newPuzzleTimeSec);
  }, 1000);

  return (
    <div className="dark:text-white sm:mb-3">{formatSec(puzzleTimeSec)}</div>
  );
}
