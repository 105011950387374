import allowedDomains from "../allowed-domains.json";

export const getReferrer = () => {
  if (typeof document == "undefined") {
    return "unknown";
  }
  return document.referrer;
};

export const getTwoferLink = () => {
  if (typeof window == "undefined") {
    return "";
  }
  return window.location.origin + window.location.pathname;
};

export const getAllowedDomains = () => {
  return Object.keys(allowedDomains);
};

export const getCurrentDomain = () => {
  if (typeof window == "undefined") {
    return "unknown";
  }
  return window.location.hostname || `referrer:${getReferrer()}`;
};

export const isAarp = () => {
  return getReferrer().includes("aarp.org");
}