import {
  v4 as uuidv4
} from "uuid";
import {
  trackUserIdAssigned
} from "./tracking";

const TWOFER_GOOFER_V1_KEY = "mw_gs_tg_v1";
const TWOFER_GOOFER_SETTINGS_V1_KEY = "mw_gs_tg_settings_v1";
const TWOFER_GOOFER_EXPERIMENTS_V1_KEY = "mw_gs_tg_experiments_v1";
const TWOFER_GOOFER_ID_KEY = "mw_gs_tg_id";
const TWOFER_GOOFER_INSTRUCTIONS_MODAL_KEY_V1 = "mw_gs_tg_instructions_dismissed_v1";
const POSTER_BANNER_DISMISSED = "mw_gs_tg_poster_banner_dismissed_v1";
const TWOFER_GOOFER_TIMER_ENABLED_KEY_V1 = "mw_gs_tg_timer_enabled_v1";
const TWOFER_GOOFER_CUSTOM_KEYBOARD_DISABLED_KEY_V1 =
  "mw_gs_tg_custom_keyboard_disabled_v1";
const FORCE_DARK_MODE_KEY = "mw_gs_tg_force_dark_mode";
const HIDDEN_BANNERS_KEY = "mw_gs_tg_hidden_banners_v1";
const NUM_TWOFERS_PLAYED_NO_ADS = 'mw_gs_tg_num_twofers_played_no_ads_v1';
const CURRENT_STREAK_DATA_KEY = 'mw_gs_tg_current_streak';

const isLocalStorageAvailable = () => {
  const test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const fakeLocalStorageState = {};
const fakeLocalStorage = {
  getItem: (key) => {
    return fakeLocalStorageState[key];
  },

  setItem: (key, val) => {
    fakeLocalStorageState[key] = val;
  },
};

const LOCAL_STORAGE = isLocalStorageAvailable() ?
  localStorage :
  fakeLocalStorage;

function getItem(key) {
  const item = LOCAL_STORAGE.getItem(key) || "{}";
  return JSON.parse(item);
}

export function getTwoferGooferState() {
  return getItem(TWOFER_GOOFER_V1_KEY);
}

export function getPersistedPuzzleState(id) {
  return getTwoferGooferState()[id] || {};
}

export function setPersistedPuzzleState(id, val) {
  const twoferGooferState = getTwoferGooferState();
  val["lastUpdated"] = new Date().getTime();
  val["savedInPuzzleResults"] = true;
  twoferGooferState[id] = val;
  LOCAL_STORAGE.setItem(
    TWOFER_GOOFER_V1_KEY,
    JSON.stringify(twoferGooferState)
  );
}

export function getSettings() {
  return getItem(TWOFER_GOOFER_SETTINGS_V1_KEY);
}

export function setSettingsValue(key, val) {
  const settings = getSettings();
  settings[key] = val;
  LOCAL_STORAGE.setItem(
    TWOFER_GOOFER_SETTINGS_V1_KEY,
    JSON.stringify(settings)
  );
}

function getExperiments() {
  return getItem(TWOFER_GOOFER_EXPERIMENTS_V1_KEY);
}

export function getExperimentValue(experimentName) {
  const experiments = getExperiments();
  return experiments[experimentName];
}

export function setExperimentValue(experimentName, val) {
  const experiments = getExperiments();
  experiments[experimentName] = val;
  LOCAL_STORAGE.setItem(
    TWOFER_GOOFER_EXPERIMENTS_V1_KEY,
    JSON.stringify(experiments)
  );
}

export function getUserId() {
  const settings = getSettings();
  if (settings[TWOFER_GOOFER_ID_KEY]) {
    return settings[TWOFER_GOOFER_ID_KEY];
  }

  const id = uuidv4();
  setSettingsValue(TWOFER_GOOFER_ID_KEY, id);
  trackUserIdAssigned(id);
  return id;
}

export function getStoredPosterBannerHidden() {
  const settings = getSettings();
  return Boolean(settings[POSTER_BANNER_DISMISSED]);
}

export function storePosterBannerHidden() {
  setSettingsValue(POSTER_BANNER_DISMISSED, true);
}

export function getHiddenBanners() {
  const settings = getSettings();
  const hiddenBanners = settings[HIDDEN_BANNERS_KEY];
  if (hiddenBanners) {
    return hiddenBanners.split(",");
  } else {
    return [];
  }
}

export function storeHiddenBanners(hiddenBanners) {
  setSettingsValue(HIDDEN_BANNERS_KEY, hiddenBanners.join(","));
}

export function haveInstructionsBeenDismissed() {
  const settings = getSettings();
  return Boolean(settings[TWOFER_GOOFER_INSTRUCTIONS_MODAL_KEY_V1]);
}

export function setInstructionsModalDismissed() {
  setSettingsValue(TWOFER_GOOFER_INSTRUCTIONS_MODAL_KEY_V1, true);
}

export function getTimerEnabled() {
  const settings = getSettings();
  return settings[TWOFER_GOOFER_TIMER_ENABLED_KEY_V1];
}

export function getDarkModeForced() {
  const settings = getSettings();
  return settings[FORCE_DARK_MODE_KEY];
}

export function storeTimerEnabled(val) {
  setSettingsValue(TWOFER_GOOFER_TIMER_ENABLED_KEY_V1, val);
}

export function getCustomKeyboardDisabled() {
  const settings = getSettings();
  return settings[TWOFER_GOOFER_CUSTOM_KEYBOARD_DISABLED_KEY_V1];
}

export function storeCustomKeyboardDisabled(val) {
  setSettingsValue(TWOFER_GOOFER_CUSTOM_KEYBOARD_DISABLED_KEY_V1, val);
}

export function storeDarkModeForced(val) {
  setSettingsValue(FORCE_DARK_MODE_KEY, val);
}

export function getStoredPuzzleTimeSec(puzzleId) {
  const currentPuzzleState = getPersistedPuzzleState(puzzleId);
  return parseInt(currentPuzzleState["puzzleTimeSec"]) || 0;
}

export function storeNumTwofersViewedNoAds(val) {
  setSettingsValue(NUM_TWOFERS_PLAYED_NO_ADS, val);

}

export function getNumTwofersViewedNoAds() {
  const val = getSettings()[NUM_TWOFERS_PLAYED_NO_ADS];
  if (!val) {
    storeNumTwofersViewedNoAds(0);
  }
  return val || 0;
}

export function getStreakData() {
  return getItem(CURRENT_STREAK_DATA_KEY);
}

export function storeStreakData(currentStreak, maxStreak) {
  LOCAL_STORAGE.setItem(CURRENT_STREAK_DATA_KEY, JSON.stringify({ currentStreak, maxStreak }));
}
