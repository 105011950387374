import { Fragment, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Toggle from "./toggle";
import { trackSettingsChange } from "../util/tracking";

import { 
  isDevEnv,
  PUBLIC_URL_MB,
  DEV_URL_MB
} from "../util/util";

export default function SettingsModal({
  open,
  setOpen,
  timerEnabled,
  setTimerEnabled,
  darkModeForced,
  setDarkModeForced,
  viewportHeight,
}) {
  async function setForceDarkModeWithTracking(val) {
    setDarkModeForced(val);
    await trackSettingsChange("force_dark_mode", val);
  }

  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [settingsModalScaleFactor, setSettingsModalScaleFactor] = useState(1);

  // Change the useEffect hook to the following
  const [isResizing, setIsResizing] = useState(true);
  const afterEnterResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setSettingsModalScaleFactor(
        (viewportHeight * 0.95) / containerRef.current.offsetHeight
      );
      setIsResizing(false);
    }
  };

  const shouldScale = viewportHeight < 500 && containerHeight > viewportHeight;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0">
          <div
            className={`flex items-center sm:items-start sm:pt-6 justify-center min-h-full text-center sm:p-0 ${
              isResizing ? "opacity-0" : "opacity-100"
            }`}
            ref={containerRef}
            style={{
              transform: shouldScale
                ? `scale(${settingsModalScaleFactor})`
                : ``,
              transformOrigin: shouldScale ? `top` : ``,
              top: shouldScale ? `20px` : ``,
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterEnter={afterEnterResize}
            >
              <Dialog.Panel className="relative bg-white dark:bg-gray-900 rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-80 sm:max-w-md">
                <div
                  className="absolute top-4 right-4 cursor-pointer z-30"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-5 w-5 text-gray-500"></XMarkIcon>
                </div>
                <div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-center text-xl font-semibold w-full z-20 mt-4 dark:text-white"
                    >
                      Settings
                    </Dialog.Title>
                    <div className="flex flex-col px-6 mt-4">
                      <div className="flex justify-between py-3 px-3 border-b border-gray-200 font-semibold items-center dark:border-gray-700 dark:text-white">
                        <div className="">Show timer in results</div>
                        <Toggle
                          enabled={timerEnabled}
                          setEnabled={setTimerEnabled}
                        ></Toggle>
                      </div>
                      <div className="flex justify-between py-3 px-3 border-b border-gray-200 font-semibold items-center dark:border-gray-700 dark:text-white">
                        <div className="">Force dark mode</div>
                        <Toggle
                          enabled={darkModeForced}
                          setEnabled={setForceDarkModeWithTracking}
                        ></Toggle>
                      </div>
                      {/* <div className="flex justify-between py-3 px-3 border-b border-gray-200 font-semibold items-center sm:hidden dark:border-gray-700 dark:text-white">
                        <div className="">Show custom keyboard</div>
                        <Toggle
                          enabled={!disableCustomKeyboard}
                          setEnabled={(val) => {
                            setDisableCustomKeyboard(!val);
                          }}
                        ></Toggle>
                      </div> */}
                      <a
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfTOCleJ77nK8OViQjoeKKnxyYu7cphpuVcD2tB88lN3hZq2w/viewform'
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex justify-between flex justify-between py-3 px-3 border-b border-gray-200 font-semibold cursor-pointer hover:text-gray-600 dark:border-gray-700 dark:text-white dark:hover:text-gray-400 external-link">
                          <div>Send feedback</div>
                          <div className="flex items-center text-gray-500 dark:text-gray-300">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>

                      {/* <div className="text-center blue-text font-semibold text-xs pb-6">We&apos;ll credit you if we use it!</div> */}

                      <div className="mt-2 text-xs flex space-x-2 justify-center">
                        <a className="cursor-pointer text-gray-400 hover:text-gray-600 underline" target="_blank" rel="noreferrer" href={'https://' + (isDevEnv() ? DEV_URL_MB: PUBLIC_URL_MB) + "/i/privacy-policy"}>
                          Privacy Policy
                        </a>
                        <a className="cursor-pointer text-gray-400 hover:text-gray-600 underline" target="_blank" rel="noreferrer" href={'https://' + (isDevEnv() ? DEV_URL_MB: PUBLIC_URL_MB) + "/i/terms-of-use"}>
                          Terms of Service
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
