import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { Bars3Icon } from "@heroicons/react/24/solid";
import InstructionsModal from "../components/instructions-modal";
import { Toaster } from "react-hot-toast";
import SettingsModal from "../components/settings-modal.jsx";
import Menu from "../components/menu";
import { isAarp } from "../util/referrer";

// import MwMenuMobile from "../components/mw-menu-mobile";

import dynamic from 'next/dynamic';
import GTMUtils from "../util/GTM.utils.js";
const MwMenuMobile = dynamic(() => import('../components/mw-menu-mobile'), {
  ssr: false,
});

export default function BasePage({
  pageContent,
  rightMenuContent,
  instructionsModalOpen,
  setInstructionsOpenHandler,
  isDarkMode,
  settingsModalOpen,
  timerEnabled,
  setTimerEnabledWithTracking,
  disableCustomKeyboard,
  setDisableCustomKeyboardWithTracking,
  darkModeForced,
  setDarkModeForced,
  menuOpen,
  setMenuOpen,
  setSettingsModalOpenWithTracking,
  setInstructionsOpenWithTracking,
  enableSettingsModal,
  playRandomTwofer,
  viewportHeight,
  scaleFactor,
  setCalendarModalOpenWithTracking,
}) {
  // Write a hook that responds in change to darkModeForced
  useEffect(() => {
    if (darkModeForced) {
      const body = document.querySelector("body");
      body.classList.add("dark");
    }

    if (!darkModeForced) {
      // make sure dark mode is off
      const body = document.querySelector("body");
      body.classList.remove("dark");
    }
  }, [darkModeForced]);
  useEffect(() => {
    if (isAarp()) {
      document.body.classList.add("hide-external-links");
    }
  }, []);

  return (
    <main
      className={`container text-center ${
        viewportHeight < 500 ? "py-2" : "py-4"
      } overflow-y-auto sm:overflow-y-visible mx-auto max-w-xl`}
    >
      <div className="hidden sm:block">
        <Toaster></Toaster>
      </div>
      <InstructionsModal
        open={instructionsModalOpen}
        setOpen={setInstructionsOpenHandler}
        isDarkMode={isDarkMode}
        viewportHeight={viewportHeight}
        scaleFactor={scaleFactor}
      ></InstructionsModal>
      {enableSettingsModal && (
        <SettingsModal
          open={settingsModalOpen}
          setOpen={setSettingsModalOpenWithTracking}
          timerEnabled={timerEnabled}
          setTimerEnabled={setTimerEnabledWithTracking}
          disableCustomKeyboard={disableCustomKeyboard}
          setDisableCustomKeyboard={setDisableCustomKeyboardWithTracking}
          darkModeForced={darkModeForced}
          setDarkModeForced={setDarkModeForced}
          viewportHeight={viewportHeight}
          scaleFactor={scaleFactor}
        ></SettingsModal>
      )}

      <MwMenuMobile 
      />

      <div className="flex justify-center align-top gap-6 text-3xl font-semibold items-start relative">
       
        <div
          className="-mt-1 sm:w-80 sm:h-16 w-36 h-12"
          style={{ position: "relative" }}
        >
          <h1>
            <Link href="/" passHref legacyBehavior>
              <a>
              <Image
                className="flex justify-center aspect-auto cursor-pointer"
                src={`${process.env.BASE_PATH}/twofer-goofer-logo${isDarkMode ? "-dark" : ""}.svg`}
                alt="Twofer Goofer"
                layout="fill"
              />
              </a>
            </Link>
          </h1>
        </div>
        <div className="flex gap-2 absolute right-0">{rightMenuContent}</div>
      </div>
      {pageContent}
    </main>
  );
}
