import {
  getUserId
} from "./local-storage";
import {
  getCurrentDomain,
  getReferrer
} from "./referrer";
import supabase from "./supabase";

export const trackClick = async (element, additionalData = {}) => {
  await supabase.from("tracking_events").insert([{
    event_type: "click",
    data: {
      element: element,
      ...additionalData,
    },
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackAdShown = async () => {
  await supabase.from("tracking_events").insert([{
    event_type: "ad_shown",
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackSettingsChange = async (setting, val) => {
  await supabase.from("tracking_events").insert([{
    event_type: "settings_change",
    data: {
      setting: setting,
      value: val,
    },
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackClueUsed = async (clueId, puzzleId) => {
  await supabase.from("tracking_events").insert([{
    event_type: "clue_used",
    data: {
      clue_type: clueId,
      puzzle_id: puzzleId,
    },
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackUserIdAssigned = async (userId) => {
  await supabase.from("tracking_events").insert([{
    event_type: "user_id_assigned",
    logged_out_user_id: userId,
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackFetchError = async (data) => {
  await supabase.from("tracking_events").insert([{
    event_type: "fetch_error",
    data: data,
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackAnswerFeedback = async (data) => {
  await supabase.from("tracking_events").insert([{
    event_type: "answer_feedback",
    data: data,
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};


export const trackAnswerSubmitted = async (answerSubmittedData, puzzleId) => {
  await supabase.from("tracking_events").insert([{
    event_type: "answer_submitted",
    data: answerSubmittedData,
    puzzle_id: puzzleId,
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};

export const trackExperimentTreatment = async (
  experimentName,
  experimentGroup
) => {
  await supabase.from("tracking_events").insert([{
    event_type: "experiment_treatment",
    data: {
      experiment_name: experimentName,
      experiment_group: experimentGroup,
    },
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
};