import { createClient } from "@supabase/supabase-js";

export const createFetch =
  (options: Pick<RequestInit, "next" | "cache">) =>
  (url: RequestInfo | URL, init?: RequestInit) => {
    return fetch(url, {
      ...init,
      ...options,
    });
  };

const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_KEY;
const supabaseUrl = process.env.NEXT_SUPABASE_PROJECT_URL;
export default createClient(
  `${supabaseUrl}`,
  supabaseKey,
  {
    global: {
      fetch: createFetch({
        next: {
          revalidate: 120,
        },
      }),
    },
  }
)
;
